import { useForm } from '@zupr/form'
import { t } from '@zupr/i18n'
import { deliveryMethodsQuery } from '@zupr/queries/order'
import { Location, ShippingDetails } from '@zupr/types/fo'
import { Form } from '@zupr/types/form'
import { DeliveryMethodType } from '@zupr/types/graphql'
import { Pro6ppNL } from '@zupr/types/pro6pp'
import { formatPrice } from '@zupr/utils/price'
import { useCallback, useContext, useMemo } from 'react'
import { useQuery } from 'urql'

import { formatAddress, formatZipcode } from '@zupr/utils/delivery'
import ShippingDetailsContext, { useShopper } from '../../../../context/shopper'

export const usePersonalDetailsForm = () => {
    const { shippingDetails } = useContext(ShippingDetailsContext)

    const form = useForm({
        pause: true,
        values: {
            ...shippingDetails,
            newsletter: false,
            terms: false,
            personalConfirmed: false,
        },
        fields: {
            name: {
                type: 'string',
                required: true,
                label: 'Name',
                max_length: 255,
            },
            phone: {
                type: 'string',
                label: 'Phonenumber',
                required: true,
            },
            email: {
                type: 'email',
                label: 'Email',
                required: true,
                max_length: 300,
            },
            emailVerify: {
                type: 'email',
                label: 'E-mailadres herhalen',
                required: true,
                max_length: 300,
                placeholder: 'Herhaal het e-mailadres',
            },
            newsletter: {
                type: 'boolean',
                label: 'Newsletter',
            },
            terms: {
                type: 'confirm',
                label: 'Terms',
                required: true,
            },
            vatReceipt: {
                type: 'boolean',
                label: 'Receipt',
            },
            invoice: {
                type: 'boolean',
                label: 'Billing information',
            },
        },
    })

    return form
}

export const useShippingDetails = () => {
    const { shippingDetails } = useContext(ShippingDetailsContext)
    return shippingDetails
}

export const useShippingDetailsForm = () => {
    const { shippingDetails, updateShippingDetails } = useContext(
        ShippingDetailsContext
    )

    const { setPosition, setDistance } = useShopper()

    const form = useForm({
        pause: true,
        values: {
            ...shippingDetails,
            vatReceipt: false,
            newsletter: false,
            terms: false,
            deliveryAddressConfirmed: false,
            invoiceAddressConfirmed: false,
        },
        fields: {
            name: {
                type: 'string',
                required: true,
                label: 'Name',
                max_length: 255,
            },
            company: {
                type: 'string',
                label: 'Company',
                max_length: 255,
            },
            zipcode: {
                type: 'string',
                label: 'Zipcode',
                required: true,
                max_length: 20,
                regex: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i,
                placeholder: '9700 AA',
            },
            address: {
                type: 'string',
                label: 'Address',
                required: true,
                max_length: 255,
                regex: /.*[a-zA-Z].*/,
            },
            city: {
                type: 'string',
                label: 'City',
                required: true,
                max_length: 255,
            },
            number: {
                type: 'string',
                label: 'House number',
                required: true,
                max_length: 20,
                regex: /^[0-9].*/,
                placeholder: '123',
            },
            deliverInstructions: {
                type: 'string',
                label: 'Opmerking voor de bezorger',
                placeholder: 'Schrijf hier een opmerking voor de bezorger...',
            },
            numberAddition: {
                type: 'string',
                label: 'Addition',
                max_length: 10,
            },
            invoiceName: {
                type: 'string',
                label: 'Name',
                max_length: 255,
            },
            invoiceCompany: {
                type: 'string',
                label: 'Company',
                max_length: 255,
            },
            invoiceZipcode: {
                type: 'string',
                label: 'Zipcode',
                max_length: 20,
            },
            invoiceAddress: {
                type: 'string',
                label: 'Address',
                max_length: 255,
            },
            invoiceCity: {
                type: 'string',
                label: 'City',
                max_length: 255,
            },
            invoiceNumber: {
                type: 'string',
                label: 'House number',
                max_length: 20,
            },
            invoiceNumberAddition: {
                type: 'string',
                label: 'Addition',
                max_length: 10,
            },
            phone: {
                type: 'string',
                label: 'Phonenumber',
                required: true,
            },
            email: {
                type: 'email',
                label: 'Email',
                required: true,
                max_length: 300,
            },
            emailVerify: {
                type: 'email',
                label: 'E-mailadres herhalen',
                placeholder: 'Herhaal het e-mailadres',
                required: true,
                max_length: 300,
            },
            newsletter: {
                type: 'boolean',
                label: 'Newsletter',
            },
            terms: {
                type: 'confirm',
                label: 'Terms',
                required: true,
            },
            vatReceipt: {
                type: 'boolean',
                label: 'Receipt',
            },
            invoice: {
                type: 'boolean',
                label: 'Billing information',
            },
        },
    })

    const zipcode = form.getField('zipcode')
    const number = form.getField('number')
    const numberAddition = form.getField('numberAddition')

    const updateForm = useCallback(
        (details: ShippingDetails) => {
            Object.keys(details).forEach(
                (key) => {
                    form.setValue(key, details[key])
                },
                [details, form]
            )
            updateShippingDetails({
                ...shippingDetails,
                ...details,
            })
        },
        [form, shippingDetails, updateShippingDetails]
    )

    const validateAddress = useCallback(() => {
        const body = form.getBody()
        updateShippingDetails(body)

        fetch('/api/maps/autocomplete-address', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                zipcode: body.zipcode,
                number: body.number,
                numberAddition: body.numberAddition,
            }),
        })
            .then((reponse) => {
                if (!reponse.ok) throw new Error('invalid address')
                return reponse.json()
            })
            .then((data: Pro6ppNL) => {
                updateForm({
                    number: data.streetNumber && `${data.streetNumber}`,
                    zipcode: formatZipcode(data.postalCode),
                    address: data.street,
                    city: data.settlement,
                    numberAddition: data.premise,
                })
                setPosition({
                    lat: data.lat,
                    lng: data.lng,
                })
            })
            .catch(() => null) // allowed to be invalid
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipcode.value, number.value, numberAddition.value])

    const reset = useCallback(() => {
        updateForm({
            number: null,
            zipcode: null,
            address: null,
            city: null,
            numberAddition: null,
        })
        setPosition(null)
        setDistance(null)
    }, [setDistance, setPosition, updateForm])

    return { form, reset, validateAddress }
}

interface UseDeliveryProps {
    locationId: Location['id']
    zipcode?: string
    pause?: boolean
}

export const useDeliveryMethods = ({
    locationId,
    zipcode,
    pause,
}: UseDeliveryProps): DeliveryMethodType[] | undefined => {
    const [deliveryMethodsResult] = useQuery({
        query: deliveryMethodsQuery,
        pause,
        variables: {
            locationId,
            zipcode: zipcode || '',
        },
    })

    const methods = useMemo<DeliveryMethodType[] | undefined>(() => {
        return (
            deliveryMethodsResult.data?.deliveryMethods.edges.map(
                ({ node }) => node
            ) || undefined
        )
    }, [deliveryMethodsResult.data?.deliveryMethods.edges])

    return methods
}

interface Shipping {
    form: Form
    deliverable?: boolean
    methods?: DeliveryMethodType[]
    deliveryCosts?: number
    deliveryAddress?: string
    estimatedDeliveryPrice: string
    validateAddress: () => void
}

interface UseShippingProps {
    locationId: Location['id']
    subTotal?: number
    deliveryMethodId?: string
}

const useShipping = ({
    locationId,
    subTotal,
    deliveryMethodId,
}: UseShippingProps): Shipping => {
    const { form, validateAddress } = useShippingDetailsForm()

    const zipcode = form.getField('zipcode')
    const address = form.getField('address')
    const number = form.getField('number')
    const numberAddition = form.getField('numberAddition')
    const city = form.getField('city')

    const methods = useDeliveryMethods({
        locationId,
        zipcode: zipcode.value,
        pause: !zipcode.isValid(),
    })

    const deliverable = useMemo<boolean | undefined>(() => {
        if (!zipcode.value) return undefined
        return methods?.some((method) => method.canDeliver) || undefined
    }, [methods, zipcode.value])

    const deliveryCosts = useMemo(() => {
        if (!deliveryMethodId) return undefined
        const method = methods?.find((method) => method.id === deliveryMethodId)

        if (!method) return undefined

        if (
            method.freeDeliveryThreshold &&
            subTotal &&
            subTotal >= method.freeDeliveryThreshold
        )
            return 0
        return method.price
    }, [methods, deliveryMethodId, subTotal])

    const estimatedDeliveryPrice = useMemo(() => {
        if (deliveryCosts !== undefined) return formatPrice(deliveryCosts)

        // if zipcode is set filter out the non deliverable methods
        const deliverableMethods =
            methods
                // filter out the non deliverable methods
                ?.filter((method) => {
                    if (!zipcode.value) return true
                    return method.canDeliver
                })
                // filter out the methods that have a minimum order amount
                .filter((method) => {
                    if (!method.minimumOrderAmount) return true
                    if (!subTotal) return true
                    return method.minimumOrderAmount <= subTotal
                }) || []

        // the price of the cheapest deliverable method
        const prices = deliverableMethods.map((method) => method.price)
        const price = Math.min(...prices)

        if (!deliverable) return 'niet bezorgbaar'
        if (price === undefined) return 'onbekend'

        if (price > 0) {
            const thresholdPrices = deliverableMethods
                .map((method) => method.freeDeliveryThreshold)
                .filter((threshold) => threshold > 0)

            if (thresholdPrices.length > 1) {
                return (
                    'Gratis vanaf ' + formatPrice(Math.min(...thresholdPrices))
                )
            }
        }

        // when no cartValue given return the cheapest delivery method
        return `${
            deliverableMethods.length > 1 ? `${t('price.starting')} ` : ''
        }${formatPrice(price)}`
    }, [deliverable, deliveryCosts, methods, subTotal, zipcode.value])

    // string representation of the delivery address
    const deliveryAddress = useMemo(() => {
        return formatAddress({
            address: address.value,
            number: number.value,
            numberAddition: numberAddition.value,
            zipcode: zipcode.value,
            city: city.value,
        })
    }, [
        address.value,
        city.value,
        number.value,
        numberAddition.value,
        zipcode.value,
    ])

    return useMemo(
        () => ({
            methods,
            deliverable,
            form,
            validateAddress,
            deliveryCosts,
            deliveryAddress,
            estimatedDeliveryPrice, // formated price of the cheapest deliverable method (or selected method)
        }),
        [
            estimatedDeliveryPrice,
            methods,
            deliverable,
            form,
            validateAddress,
            deliveryAddress,
            deliveryCosts,
        ]
    )
}

export default useShipping
